import React from "react";
import cocoapods from "../assets/cocoapods.png";
import imessage from "../assets/imessage.png";

const BlogArchive = () => {
  const blogs = [
    {
      img: cocoapods,
      title: "Create and Distribute Private Libraries with Cocoapods",
      url: "https://medium.com/@shahabejaz/create-and-distribute-private-libraries-with-cocoapods-5b6507b57a03",
    },
    {
      img: imessage,
      title: "Introduction to Messages Framework and Sticker Pack Application",
      url: "https://medium.com/@shahabejaz/introduction-to-messages-framework-and-sticker-pack-application-5edc72e17ceb",
    },
  ];

  return (
    <section className="bg-primary text-white px-5 py-32" id="blog">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[100px] border-indigo-600 pb-2">
            Blogs
          </h2>

          <p className="pb-5">Some of my best blogs.</p>
        </div>

        <div></div>
      </div>

      <div className="projects container mx-auto grid md:grid-cols-2 gap-10">
        {blogs.map((blog) => {
          return (
            <div>
              <img src={blog.img} alt={blog.title} />
              <h3 className="py-5 text-2xl">{blog.title}</h3>
              <a
                href={blog.url}
                className=" btn bg-accent  border-2 border-[#7477FF] text-white px-6 py-3 hover:bg-transparent"
              >
                Read More
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BlogArchive;
